import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { ExternalLink } from "../components/sitelinks";
import {
    MainContainer,
    BoxContainer,
    BoxHeading,
    SubHeading,
    BoxCopy
} from "../components/simplepagelayout";


const ContourVersionsPage = () => (
        <Layout>
        <SEO title= "Contour Rig Tools Public Release Versions" />
        <MainContainer>
          <BoxContainer>
        <BoxHeading>
        Contour Rig Tools Releases
        </BoxHeading>
        <BoxCopy>
        <p>
        Below you'll find download links for all public releases of Contour Rig Tools, in reverse chronological order. More recent releases are displayed first.
        </p>

        <SubHeading>
            v1.0.11.1 (6/10/2024)
        </SubHeading>

        <p>
        <div>
        <ExternalLink href="https://downloads.notionalpipe.com/releases/v1_0_11_1/Contour_v1.0.11.1_windows_maya2020_2024.zip">Download Rig Tools for Windows</ExternalLink>
        </div>
        <div>
        <ExternalLink href="https://downloads.notionalpipe.com/releases/v1_0_11_1/Contour_v1.0.11.1_linux_maya2020_2024.tgz">Download Rig Tools for Linux</ExternalLink>
        </div>
        <div>
        <ExternalLink href="https://downloads.notionalpipe.com/releases/v1_0_11_1/Contour_v1.0.11.1_macOS_maya2020_2024.zip">Download Rig Tools for macOS</ExternalLink>
        </div>
        </p>

        <SubHeading>
            v1.0.11 (5/24/2024)
        </SubHeading>

        <p>
        <div>
        <ExternalLink href="https://downloads.notionalpipe.com/releases/v1_0_11/Contour_v1.0.11_windows_maya2020_2024.zip">Download Rig Tools for Windows</ExternalLink>
        </div>
        <div>
        <ExternalLink href="https://downloads.notionalpipe.com/releases/v1_0_11/Contour_v1.0.11_linux_maya2020_2024.tgz">Download Rig Tools for Linux</ExternalLink>
        </div>
        <div>
        <ExternalLink href="https://downloads.notionalpipe.com/releases/v1_0_11/Contour_v1.0.11_macOS_maya2020_2024.zip">Download Rig Tools for macOS</ExternalLink>
        </div>
        </p>

        <SubHeading>
            v1.0.10 (2/7/2024)
        </SubHeading>

        <p>
        <div>
        <ExternalLink href="https://downloads.notionalpipe.com/releases/v1_0_10/Contour_v1.0.10_windows_maya2020_2024.zip">Download Rig Tools for Windows</ExternalLink>
        </div>
        <div>
        <ExternalLink href="https://downloads.notionalpipe.com/releases/v1_0_10/Contour_v1.0.10_linux_maya2020_2024.tgz">Download Rig Tools for Linux</ExternalLink>
        </div>
        <div>
        <ExternalLink href="https://downloads.notionalpipe.com/releases/v1_0_10/Contour_v1.0.10_macOS_maya2020_2024.zip">Download Rig Tools for macOS</ExternalLink>
        </div>
        </p>

        <SubHeading>
            v1.0.9 (11/24/2023)
        </SubHeading>

        <p>
        <div>
        <ExternalLink href="https://downloads.notionalpipe.com/releases/v1_0_9/Contour_v1.0.9_windows_maya2020_2024.zip">Download Rig Tools for Windows</ExternalLink>
        </div>
        <div>
        <ExternalLink href="https://downloads.notionalpipe.com/releases/v1_0_9/Contour_v1.0.9_linux_maya2020_2024.tgz">Download Rig Tools for Linux</ExternalLink>
        </div>
        <div>
        <ExternalLink href="https://downloads.notionalpipe.com/releases/v1_0_9/Contour_v1.0.9_macOS_maya2020_2024.zip">Download Rig Tools for macOS</ExternalLink>
        </div>
        </p>

        <SubHeading>
            v1.0.8 (11/1/2023)
        </SubHeading>

        <p>
        <div>
        <ExternalLink href="https://downloads.notionalpipe.com/releases/v1_0_8/Contour_v1.0.8_windows_maya2020_2024.zip">Download Rig Tools v1.0.8 for Windows</ExternalLink>
        </div>
        <div>
        <ExternalLink href="https://downloads.notionalpipe.com/releases/v1_0_8/Contour_v1.0.8_linux_maya2020_2024.tgz">Download Rig Tools v1.0.8 for Linux</ExternalLink>
        </div>
        <div>
        <ExternalLink href="https://downloads.notionalpipe.com/releases/v1_0_8/Contour_v1.0.8_macOS_maya2020_2024.zip">Download Rig Tools v1.0.8 for macOS</ExternalLink>
        </div>
        </p>

        <SubHeading>
            v1.0.7 (11/9/2022)
        </SubHeading>

        <p>
        <div>
        <ExternalLink href="https://downloads.notionalpipe.com/releases/v1_0_7/Contour_v1.0.7_windows_maya2018_2023.zip">Download Rig Tools v1.0.7 for Windows</ExternalLink>
        </div>
        <div>
        <ExternalLink href="https://downloads.notionalpipe.com/releases/v1_0_7/Contour_v1.0.7_linux_maya2018_2023.tgz">Download Rig Tools v1.0.7 for Linux</ExternalLink>
        </div>
        <div>
        <ExternalLink href="https://downloads.notionalpipe.com/releases/v1_0_7/Contour_v1.0.7_macOS_maya2018_2023.zip">Download Rig Tools v1.0.7 for macOS</ExternalLink>
        </div>
        </p>

        <SubHeading>
           v1.0.6.1 (9/3/2022)
        </SubHeading>

        <p>
        <div>
        <ExternalLink href="https://downloads.notionalpipe.com/releases/v1_0_6_1/Contour_v1.0.6.1_windows_maya2018_2022.zip">Download Rig Tools v1.0.6.1 for Windows</ExternalLink>
        </div>
        <div>
        <ExternalLink href="https://downloads.notionalpipe.com/releases/v1_0_6_1/Contour_v1.0.6.1_linux_maya2018_2022.tgz">Download Rig Tools v1.0.6.1 for Linux</ExternalLink>
        </div>
        <div>
        <ExternalLink href="https://downloads.notionalpipe.com/releases/v1_0_6_1/Contour_v1.0.6.1_macOS_maya2018_2022.zip">Download Rig Tools v1.0.6.1 for macOS</ExternalLink>
        </div>
        </p>

        <SubHeading>
           v1.0.6 (5/23/2022)
        </SubHeading>

        <p>
        <div>
        <ExternalLink href="https://downloads.notionalpipe.com/releases/v1_0_6/Contour_v1.0.6_windows_maya2018_2022.zip">Download Rig Tools v1.0.6 for Windows</ExternalLink>
        </div>
        <div>
        <ExternalLink href="https://downloads.notionalpipe.com/releases/v1_0_6/Contour_v1.0.6_linux_maya2018_2022.tgz">Download Rig Tools v1.0.6 for Linux</ExternalLink>
        </div>
        <div>
        <ExternalLink href="https://downloads.notionalpipe.com/releases/v1_0_6/Contour_v1.0.6_macos_maya2018_2022.zip">Download Rig Tools v1.0.6 for macOS</ExternalLink>
        </div>
        </p>

        <SubHeading>
           v1.0.5 (1/12/2022)
        </SubHeading>

        <p>
        <div>
        <ExternalLink href="https://downloads.notionalpipe.com/releases/v1_0_5/Contour_v1.0.5_windows_maya2018_2022.zip">Download Rig Tools v1.0.5 for Windows</ExternalLink>
        </div>
        <div>
        <ExternalLink href="https://downloads.notionalpipe.com/releases/v1_0_5/Contour_v1.0.5_linux_maya2018_2022.tgz">Download Rig Tools v1.0.5 for Linux</ExternalLink>
        </div>
        <div>
        <ExternalLink href="https://downloads.notionalpipe.com/releases/v1_0_5/Contour_v1.0.5_macos_maya2018_2022.zip">Download Rig Tools v1.0.5 for macOS</ExternalLink>
        </div>
        </p>

        <SubHeading>
           v1.0.4 (12/13/2021)
        </SubHeading>
        <p>
        <div>
        <ExternalLink href="https://downloads.notionalpipe.com/releases/v1_0_4/Contour_v1.0.4_windows_maya2018_2022.zip">Download Rig Tools v1.0.4 for Windows</ExternalLink>
        </div>
        <div>
        <ExternalLink href="https://downloads.notionalpipe.com/releases/v1_0_4/Contour_v1.0.4_linux_maya2018_2022.tgz">Download Rig Tools v1.0.4 for Linux</ExternalLink>
        </div>
        <div>
        <ExternalLink href="https://downloads.notionalpipe.com/releases/v1_0_4/Contour_v1.0.4_macos_maya2018_2022.zip">Download Rig Tools v1.0.4 for macOS</ExternalLink>
        </div>
        </p>

        <SubHeading>
           v1.0.3 (10/31/2021)
        </SubHeading>
        <p>
        <div>
        <ExternalLink href="https://downloads.notionalpipe.com/releases/v1_0_3/Contour_v1.0.3_windows_maya2018_2022.zip">Download Rig Tools v1.0.3 for Windows</ExternalLink>
        </div>
        <div>
        <ExternalLink href="https://downloads.notionalpipe.com/releases/v1_0_3/Contour_v1.0.3_linux_maya2018_2022.tgz">Download Rig Tools v1.0.3 for Linux</ExternalLink>
        </div>
        <div>
        <ExternalLink href="https://downloads.notionalpipe.com/releases/v1_0_3/Contour_v1.0.3_macos_maya2018_2022.zip">Download Rig Tools v1.0.3 for macOS</ExternalLink>
        </div>
        </p>
        </BoxCopy>
        </BoxContainer>

        </MainContainer>
    </Layout>
);

export default ContourVersionsPage;
